@import "../../assets/styles/base/_resets";
@import "../../assets/styles/base/_typography";
@import "../../assets/styles/_variables";

.mission_wrapper {
  // height: 700px;
  padding: 50px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(196, 196, 196, 0.07);

  .inner {
    width: 60%;
    border: 10px solid $dark-grey;
    padding: 50px;


    h2 {
      color: $dark-grey;
      font-weight: 700;
      font-size: 56px;
      line-height: 72px;
    }

    h4 {
      color: $light-grey;
      margin: 20px;
      font-size: 18px;
    }

    hr {
      background-color: $primary;
      height: 5px;
      width: 60px;
      margin: 30px auto;
    }
  }
}

@media screen and (max-width: 650px) {
  .mission_wrapper {
    .inner {
      width: 80%;

      h2 {
        font-size: 36px;
        line-height: 44px;
      }

      h4 {
        font-size: 14px;
      }
    }
  }
}
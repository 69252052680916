@import "../../assets/styles/base/_resets";
@import "../../assets/styles/base/_typography";
@import "../../assets/styles/_variables";

.wrapper {
  width: 100%;
  height: 400px;
  // background-color: rgb(234, 40, 144);
  background-color: $primary;
  color: $white;
  padding: 100px 20px;
  text-align: center;

  h2 {
    font-size: 32px;
    font-weight: bold;
    width: 400px;
    margin: 0 auto;
  }

  p {
    margin: 40px 0;
  }

  button {
    width: 150px;
    height: 50px;
    background-color: transparent;
    color: $white;
    border: 1px solid $white;

    &:hover {
      background-color: $white;
      // color: rgb(234, 40, 144);
      color: $primary;
      transition: all 0.5s ease;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 700px) {
  .wrapper {
    padding: 40px 20px;
    h2 {
      width: 275px;
    }
  }
}

@media screen and (max-width: 500px) {
  .wrapper {
    padding: 30px 10px;
    
    h2 {
      width: 250px;
    }
  }
}
@import "../../assets/styles/base/_resets";
@import "../../assets/styles/base/_typography";
@import "../../assets/styles/_variables";

.nav_items {
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.1rem;
  background-color: #112534;

  a {
    button {
      width: 120px;
      height: 50px;
      background-color: $primary;
      color: $white;
      border: none;
      margin-right: 20px;
  
      &:hover {
        background-color: $white;
        color: $primary;
        transition: all 0.5s ease;
        cursor: pointer;
      }
    }
  }

  .navbar_logo {
    // justify-self: start;
    margin-left: 30px;
    cursor: pointer;
    width: 75px;
    height: 70px;
  }

  .menu-icon {
    display: none;
  }

  .nav-menu {
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 10px;
    list-style: none;
    text-align: center;
    width: 75%;
    justify-content: end;
    margin-bottom: 0 !important;

    .nav-links {
      text-decoration: none;
      padding: 0.5rem 1rem;
      color: $white;
    
      &:hover {
        color: $primary;
        transition: all 0.2s ease-out;
        cursor: pointer;
      }
    }
  }
}


@media screen and (max-width: 1150px) {
  .nav_items {
    position: relative;

    .navbar_logo {
      position: absolute;
      top: 0;
      left: 0;
      // height: 40px;
      // width: 40px;
      margin-left: 10px;
      margin-bottom: 10px;
      transform: translate(20%, 20%);
    }

    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 300px;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transform: all 0.5s ease;
    }

    .nav-menu.active {
      background: $primary;
      color: $primary;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;

      .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
        color: white;
        text-decoration: none;
        font-weight: bold;
      }
    }

    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 40%);
      font-size: 1.8rem;
      color: white;
    }

    button {
      display: none;
    }
  }
}

// @media screen and (max-width: 1000px) {
//   .nav_items {
//     .navbar_logo {
//       width: 35px;
//     height: 35px;
//     }
//   }
// }

// @media screen and (max-width: 650px) {
//   .nav_items {
//     .navbar_logo {
//       width: 35px;
//     height: 35px;
//     }
//   }
// }
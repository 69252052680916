@import "../../assets/styles/base/_resets";
@import "../../assets/styles/base/_typography";
@import "../../assets/styles/_variables";

.why {
  width: 80%;
  margin: 200px auto;
  
  h3 {
    text-align: center;
    margin: 80px 0;
    font-size: 44px;
    color: $dark-grey;
  }

  hr {
    width: 50px;
    height: 5px;
    background-color: $primary;
    margin: auto;
  }

  p {
    margin: 30px 0;
    color: $light-grey;
  }

  div {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 150px;

    img {
      height: 320px;
      width: 300px;
    }
  }
}

.sessions {
  width: 80%;
  margin: 200px auto;
  display: flex;
  flex-direction: column;

  h3 {
    text-align: center;
    margin: 60px 0;
    font-size: 44px;
    color: $dark-grey;
  }

  hr {
    width: 50px;
    height: 5px;
    background-color: $primary;
    margin: auto;
  }

  p {
    margin: 20px 0;
    color: $light-grey;

    span {
      font-weight: bold;
      margin-right: 5px;
    }
  }

  img {
    width: 340px;
    height: 380px;
    margin: 70px auto;
  }
}

.nutshell {
  padding: 80px 0;
  width: 100%;
  // height: 750px;
  background: rgba(196, 196, 196, 0.07);

  h3 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 44px;
    font-weight: 700;
    color: $dark-grey;
  }

  hr {
    width: 50px;
    height: 5px;
    background-color: $primary;
    margin: auto;
    margin-bottom: 40px;
  }

  .details {
    width: 85%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 50px;

    article {
      margin: 20px 0;
      padding: 20px;

      h4 {
        color: $primary;
        font-weight: bold;
        font-size: 22px;
        margin: 10px 0;
      }

      h6 {
        color: $primary;
        font-size: 16px;
        margin: 20px 0;
      }

      ul {
        list-style-type: none;

        li {
          color: $light-grey;
          margin: 15px 0;
          line-height: 18px;
        }
      } 
    }
  }
}

@media screen and (max-width: 900px) {
  .nutshell {
    // height: 1000px;
    .details {
      grid-template-columns: 1fr;
    }
  }
}

@media screen and (max-width: 750px) {
  .why {
    div {
      flex-direction: column;

      img {
        height: 260px;
        width: 260px;
        margin: 20px 0;
      }
    }
  }

  .sessions {
    img {
      width: 200px;
      height: 190px;
    }
  }
}
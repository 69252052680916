@import "../../assets/styles/base/_resets";
@import "../../assets/styles/base/_typography";
@import "../../assets/styles/_variables";


.landing {
  width: 100%;
  height: 800px;

  img {
    width: 100%;
    height: 100%;
  }

  .text_wrapper {
    width: 60%;
    // position: relative;
    margin-top: -40%;
    padding-left: 300px;

    h1 {
      color: $white;
      font-weight: 700;
      line-height: 74px;
      font-size: 56px;
    }

    span {
      color: $white;
      font-size: 70px;
      // line-height: 36px;
      margin: 50px 0;
    }

    
  }
}

.wrapper {
  width: 100%;
  height: 400px;
  background: rgba(196, 196, 196, 0.07);
  color: $dark-grey;
  padding: 100px 20px;
  text-align: center;

  h2 {
    font-size: 44px;
    font-weight: bold;
    width: 100%;
    margin: 0 auto;
  }

  p {
    margin: 40px 0;
    font-size: 22px;
    line-height: 26px;
    color: $light-grey;
  }

  hr {
    background-color: $primary;
    height: 5px;
    width: 60px;
    margin: 30px auto;
  }
}

.contact_container {
  width: 100%;
  // height: 750px;
  display: flex;
}


.map_wrapper {
  width: 100%;

  iframe {
    width: 100%;
    border: none;
  }
}




@media screen and (max-width: 1250px) {
  .landing {
    .text_wrapper {
      margin-top: -50%;
      padding-left: 250px;
    }
  }
}




@media screen and (max-width: 1100px) {
  .contact_container {
    flex-direction: column;
  }
}


@media screen and (max-width: 1000px) {
  .landing {
    height: 650px;

    .text_wrapper {
      width: 75%;
      margin-top: -60%;
      padding-left: 220px;
    }
  }
}

@media screen and (max-width: 750px) {
  .landing {
    height: 550px;

    .text_wrapper {
      width: 75%;
      margin-top: -70%;
      padding-left: 200px;
    }
  }
}

@media screen and (max-width: 550px) {
  .landing {
    height: 450px;

    .text_wrapper {
      width: 75%;
      margin-top: -80%;
      padding-left: 120px;
      
      h1 {
        font-size: 32px;
      }
    }
  }

  .map_wrapper {
    width: 100%;
  
    iframe {
      width: 100%;
      border: none;
    }
  }
}

@media screen and (max-width: 405px) {
  .landing {
    .text_wrapper {
      width: 85%;
      margin-top: -100%;
      padding-left: 80px;

      h1 {
        font-size: 28px;
      }

      span {
        font-size: 36px;
      }
    }
  }
}
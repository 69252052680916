@import "../../assets/styles/base/_resets";
@import "../../assets/styles/base/_typography";
@import "../../assets/styles/_variables";


.landing {
  width: 100%;
  height: 800px;

  img {
    width: 100%;
    height: 100%;
  }

  .text_wrapper {
    width: 60%;
    // position: relative;
    margin-top: -40%;
    padding-left: 300px;

    h1 {
      color: $white;
      font-weight: 700;
      line-height: 74px;
      font-size: 56px;
    }

    p {
      color: $tertiary;
      font-size: 14px;
      line-height: 36px;
      margin: 20px 0;
    }

    button {
      width: 150px;
      padding: 10px;
      background: transparent;
      color: $white;
      border: 2px solid $white;

      &:hover {
        background-color: $white;
        color: $primary;
        transition: all 0.5s ease;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .landing {
    .text_wrapper {
      margin-top: -50%;
      padding-left: 250px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .landing {
    height: 650px;

    .text_wrapper {
      width: 75%;
      margin-top: -60%;
      padding-left: 220px;
    }
  }
}

@media screen and (max-width: 750px) {
  .landing {
    height: 550px;

    .text_wrapper {
      width: 70%;
      margin-top: -70%;
      padding-left: 100px;

      h1 {
        font-size: 44px;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .landing {
    height: 450px;

    .text_wrapper {
      width: 75%;
      margin-top: -80%;
      padding-left: 120px;
      
      h1 {
        font-size: 32px;
      }

      p {
        font-size: 12px;
      }

      button {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 405px) {
  .landing {
    .text_wrapper {
      width: 85%;
      margin-top: -95%;
      padding-left: 80px;
    }
  }
}
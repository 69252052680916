@import "../../assets/styles/base/_resets";
@import "../../assets/styles/base/_typography";
@import "../../assets/styles/_variables";

.map_wrapper {
  width: 60%;

  iframe {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 1100px) {
  .map_wrapper {
    width: 100%;
    height: 300px;
  }
}
@import "../../assets/styles/base/_resets";
@import "../../assets/styles/base/_typography";
@import "../../assets/styles/_variables";

.testimonial {
  width: 50%;
  height: 70%;
  color: $white;
  margin: 30px auto;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  
  img {
    height: 95px;
    width: 105px;
    border-radius: 50%;
  }

  h4 {
    font-size: 32px;
    font-weight: 700;
  }

  p {
    font-size: 14px;
  }
}

@media screen and (max-width: 700px) {
  .testimonial {
    p {
      text-align: center;
    }
  }
}
@import "../../assets/styles/base/_resets";
@import "../../assets/styles/base/_typography";
@import "../../assets/styles/_variables";

.contact_info {
  padding: 60px 0;
  width: 40%;
  height: 100%;
  background-color: $primary;
  color: $white;
  background-color: $primary;

  div {
    width: 75%;
    margin: 20px auto;
  }

  div:nth-child(1) {
    // background-color: red;
    
    h3 {
      font-size: 32px;
      line-height: 40px;
      font-weight: bold;
      margin: 20px 0;
    } 
  }

  .wrapper {
    margin-top: 50px;
    height: 300px;
    // background-color: yellow;
    padding: 30px 0;
    display: grid;
    grid-template-columns: 1fr 4fr;
    font-size: 24px;
    
    

      a {
        color: $white;

        &:hover {
          transition: 0.6s ease;
          color: $secondary;
        }
      }
    
    
    // .phone {
    //   height: 25%;
    //   // background-color: blue;
    // }

    .mail {
      height: 25%;
      // background-color: green;
    }

    .location {
      height: 25%;
      // background-color: orange;
    }
  }

  .icons {
    height: 100px;
    width: 75%;
    margin: 20px auto;
    // background-color: purple;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-decoration: none;

    a {
      text-decoration: none; 
      color: $white;

      &:hover {
        transition: 0.6s ease;
        color: $secondary;
      }
    }

    > * {
      margin: 0 10px;
      font-size: 44px;

    }
  }
}

@media screen and (max-width: 1100px) {
  .contact_info {
    width: 100%;
    padding: 20px;
  }
}

@media screen and (max-width: 500px) {
  .contact_info {
    div {
      width: 85%;
    }
  }
}

@media screen and (max-width: 400px) {
  .contact_info {
    .wrapper {
      grid-template-columns: 1fr;
      // column-gap: 50px;
    }
  }
}
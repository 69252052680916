@import "../../assets/styles/base/_resets";
@import "../../assets/styles/base/_typography";
@import "../../assets/styles/_variables";

.bio {
  width: 80%;
  margin: 50px auto;
  display: flex;
  align-items: center;
  flex-direction: column;

  h1 {
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;

    span {
      color: $primary;
    }
  }

  hr {
    width: 100px;
    height: 5px;
    background-color: $primary;
    margin: 20px 0;
  }

  img {
    width: 320px;
    height: 370px;
    margin: 50px 0;
  }

  p {
    width: 70%;
    color: $dark-grey;
    margin: 20px 0;
  }
}

.qualifications {
  width: 400px;
  margin: 60px auto;
  background: rgba(64, 62, 62, 0.03);
  border-radius: 10px;
  padding: 10px;
  text-align: center;

  h3 {
    font-weight: 700;
    color: $dark-grey;
  }

  ul {
    list-style-type: none;

    li {
      margin: 10px 0;
      color: $dark-grey;
    }
  }
}

@media screen and (max-width: 450px) {
  .bio {
    width: 90%;
  }
  .qualifications {
    width: 300px;
  }
}
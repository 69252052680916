@import "../../assets/styles/base/_resets";
@import "../../assets/styles/base/_typography";
@import "../../assets/styles/_variables";

footer {
  height: 300px;
  width: 100%;
  background-color: $secondary;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 2fr;

  .mobile_icons {
    display: none;
  }

  div:nth-child(2) {
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    img {
      height: 150px;
      width: 150px;
    }

    h4 {
      color: $white;
      font-weight: bold;
      width: 200px;
      color: rgb(234, 40, 144);
    }
  }

  div:nth-child(3), div:nth-child(4) {
    padding: 50px 10px;
    color: $white;

    h4, h6 {
      width: 200px;
      margin: 20px auto;
    }

    h4 {
      color: $primary;
      font-weight: 700;
      margin-bottom: 30px;
    }

    h6 {
      color: $light-grey;
    }
  }

  div:nth-child(5) {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    button {
      width: 150px;
      height: 50px;
      background-color: $primary;
      color: $white;
      border: none;
  
      &:hover {
        background-color: $white;
        color: $primary;
        transition: all 0.5s ease;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 150px;

    .mobile_icons {
      color: $white;
      display: flex;
      font-size: 36px;

      > * {
        margin: 0 30px;
        color: $white;
      }
    }
    
    div:nth-child(2), div:nth-child(3),div:nth-child(4) {
      display: none;
    }
  }
}

@media screen and (max-width: 450px) {
  footer {
    .mobile_icons {
      > * {
        margin: 0 20px;
      }
    }
  }
}
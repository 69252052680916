@import "../../assets/styles/base/_resets";
@import "../../assets/styles/base/_typography";
@import "../../assets/styles/_variables";

.container{
  width: 100% ;
  height: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $secondary;
  font-size: 40px;
  padding: 0 30px;

  button {
    background-color: transparent;
    border: none;
    color: $white;
  }
}